<template>
  <div class="ma-10">
    <correlation-data v-if="selectedTopic === 'correlation'" />
    <correlation-discharge-data
      v-else-if="selectedTopic === 'correlationDischarge'"
    />
    <sediment-stations v-else-if="selectedTopic === 'sedimentStations'" />
    <in-situ-layer v-else-if="selectedTopic === 'inSitu'" />
    <h2 v-else>{{ $t("selectTopic") }}</h2>
  </div>
</template>

<script>
import { mapState } from "vuex";

const CorrelationData = () =>
  import("@/core/components/data/main-pages/CorrelationData.vue");
const CorrelationDischargeData = () =>
  import("@/core/components/data/main-pages/CorrelationDischargeData.vue");
const SedimentStations = () =>
  import("@/core/components/data/main-pages/SedimentStations.vue");
const InSituLayer = () =>
  import("@/core/components/data/main-pages/InSituData.vue");

export default {
  name: "CoreData",
  components: {
    CorrelationData,
    CorrelationDischargeData,
    SedimentStations,
    InSituLayer
  },
  computed: {
    ...mapState("userdata", ["selectedTopic"])
  }
};
</script>

<style scoped></style>
