<template>
  <v-main>
    <core-data />
  </v-main>
</template>

<script>
import regionMixin from "@/core/mixins/region.mixin";
import CoreData from "@/core/components/data/CoreData";

export default {
  name: "SedimentResults",
  components: { CoreData },
  mixins: [regionMixin]
};
</script>

<style scoped></style>
